@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html, body, #app, #app > div {
    width: 100%
}
.overall{
    background-color: lightgray;
    height: 100%;
}

.App {
    text-align: center;
    background: lightgray;
    color: black;
    overflow: hidden;
    font-family: 'Rubik', sans-serif;
    display: flex;
    justify-content: center;
}

.resume{
    width: 75%;
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: white;
    text-align: start;
    padding-left: 3%;
    padding-top: 3%;
    padding-bottom: 3%;
}

#root{
    height: 100vh;
    background-color: lightgray;
}

.header{
    font-size: 24px;
    padding-bottom: 3%;
    padding-top: 10px;
}

.section{
    font-size: 20px;
}

#subtext{
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: bold;
    width: 100%;
}

ul{ 
    overflow: hidden; 
    list-style-position: inside;
    padding-left: 0;
}

#subtext_bullet{
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: bold;
    width: 100%;
}

#horizontal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.horizontal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#big{
    font-size: 18px;
    padding-left: 20px;
    font-weight: bold;
    padding-right: 20px;
}

#subinfo{
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: normal;
}

#subinfogray{
    font-size: 14px;
    color: gray;
    font-weight: normal;
}

.profile_image{
    width: 25%;
    border-radius: 50%;
    border: solid black 1px;
}

.collapsible_header{
    outline: 1px solid black;
    padding: 10px;
    margin-right: 3%;
}

.btn:focus {
    outline: none;
    box-shadow: none;
    outline: none !important;
    box-shadow: none !important;
  }

.Collapsible{
    outline: solid 1px black;
    padding: 10px;
    margin-right: 3%;
    margin-top: 20px;
}

.accordion{
    outline: solid 1px black;
    margin-right: 3%;
    margin-top: 20px;
}

.custom_trigger{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modal_dtp{
    width: 60vh;
    display: block;
    padding: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    text-align: center;
    background: lightgray;
}

.vote {
    display: inline-block;
    cursor: pointer;
    color: #687074
} 
  
  
.vote.on {
    color: #f48024
}

#vertical{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 2%;
}

.votingblock{
    display: flex;
    flex-direction: row;
}

.votingblock_notes{
    display: flex;
    flex-direction: row;
    margin-left: 3%;
}

.userID{
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    background: yellow;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#red{
    color: red;
}

.title{
    justify-content: center;
    font-size: 30px;
    padding-left: 20px;
    padding-top: 10px;
}

#title{
    justify-content: center;
    font-size: 20px;
    padding-bottom: 10px;
}

.list{
    padding: 20px;
}

.userItem{
    padding-top: 10px;
}

.notes{
    max-width: 100%;
    margin-right: 3%;
    flex-wrap: wrap;
    flex-direction: column;
}

#toggle_icon{
    height: 100%;
     top: auto;
}
